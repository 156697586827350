/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Recaptcha from "react-google-invisible-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./order.styles.css";
import getEmailData from "../../../../helpers/getEmailData";
import SelectedAccessories from "./SelectedAccessories";
import { accessoryData } from "../Accessories/data";
import { getImageUrl } from "../../../../helpers/imageUrl";
import { lang } from "../../../../helpers/language";
import { plaqueData } from "../Plaque/data";
import { parseSpecialCharacter } from "../../../../helpers/parseSpecialCharacter";
import { accessories } from "../../../../helpers/trampoline-accessories";
import ProductMessage from "../ModelCards/ProductMessage";
import ProductStatus from "../ModelCards/ProductStatus";
const axios = require("axios").default;

export default class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        address: "",
        state: "",
        city: "",
        zip: props.pin,
        country: "",
        newsletter: true,
        acknowledge: false,
      },
      orderDetail: {},
      imgUrl: props.imgUrl,
      skipPin: false,
      redirect: false,
      orderStatus: false,
      error: false,
      message: "",
      sending: false,
      valid: true, // for Google recaptcha
      productImage: "",
      checkoutStatus: 0,
      makingCheckoutRequest: false,
      agree: false,
      pleaseAgree: false,
      canvasLoaded: false,
    };

    this.updateFormData = this.updateFormData.bind(this);
    this.onResolved = this.onResolved.bind(this);
  }

  componentDidMount() {
    let {
      location,
      activeProduct,
      customizationOption,
      selectedTheme,
      fontDetail,
      selectedAccessories,
      orderId
    } = this.props.state;

    let { spec, pin, contactDetail, skipPin } = this.props;
    let { L, F, M, E } = spec;

    let themeNameForUrl = "";
    if (selectedTheme && customizationOption === "theme") {
      // set the name of the theme
      themeNameForUrl = `&${selectedTheme.id}`;

      // update the font details based on the theme
      fontDetail = selectedTheme.fontDetail;
    }

    const {
      activeFont,
      fontSize,
      color,
      lineOne,
      lineTwo,
      isCustom,
      plaqueColor,
    } = fontDetail;

    const accessoriesList = () => {
      let list = [];
      selectedAccessories.forEach((accessory) => {
        const accessoryItem = accessoryData.find((acc) => acc.id === accessory);
        list.push({
          name: accessoryItem.name,
          price: accessoryItem[location][activeProduct.id],
        });
      });
      return list;
    };

    const url = `?${
      activeProduct.id
    }&${customizationOption}${themeNameForUrl}&L${L.arrangement}=${L.l1}${
      L.l2
    }${L.l3}${L.l4}${L.l5}${L.l6}&F${F.arrangement}=${F.f1}${F.f2}${F.f3}${
      F.f4
    }${F.f5}${F.f6}&M${M.arrangement}=${M.m1}${M.m2}${M.m3}${M.m4}${M.m5}${
      M.m6
    }&E${E.arrangement}=${E.e1}${E.e2}${E.e3}${E.e4}${E.e5}${
      E.e6
    }&PL=${isCustom}-${activeFont}-${fontSize}-${parseSpecialCharacter(
      lineOne
    )}-${parseSpecialCharacter(
      lineTwo
    )}-${color}-${plaqueColor}&AL=${selectedAccessories.join("-")}`;

    const imgUrl = getImageUrl({
      ...spec,
      productId: activeProduct.id,
      fontDetail: fontDetail,
    });

    if (contactDetail) {
      const existingUserInfo = this.state.userInfo;
      this.setState({
        userInfo: {
          ...existingUserInfo,
          firstName: contactDetail.firstName,
          lastName: contactDetail.lastName,
          phone: "",
          email: contactDetail.email,
        },
      });
    }

    if (orderId) {
      const self = this;
      axios
        .get("/api/order-details/" + orderId)
        .then(function (response) {
          if (response.data.status === 200) {
            const {
              first_name,
              last_name,
              phone,
              email,
              address,
              state,
              city,
              zip,
              country,
              checkoutStatus
            } = response.data.data;

            /**
             * Check if the user's PIN has shipping or not
             * if pin doesn't exist in the order
             * it is for trial
             * hide address by setting 'skipPin' as 'true'
             */
            let skipPin = false;
            if ((zip && zip.trim() === "") || zip === null) {
              skipPin = true;
            }
            const userInfo = {
              firstName: first_name,
              lastName: last_name,
              phone,
              email,
              address,
              state,
              city,
              zip,
              country,
              newsletter: true,
              acknowledge: false,
            };
            self.setState({
              skipPin,
              userInfo,
              checkoutStatus
            });
          }
        })
        .catch(function (error) {
          self.setState({
            error: true,
            loading: false,
          });
        });
    }

    const selectedPlaque = plaqueData.find(
      (pl) => pl.color === fontDetail.color
    );

    this.setState({
      skipPin: skipPin,
      orderDetail: {
        location,
        selectedAccessories,
        accessoriesList: accessoriesList(),
        customizationOption,
        selectedTheme,
        activeProductId: activeProduct.id,
        activeProduct: activeProduct,
        fontDetail: {
          ...fontDetail,
          designSelection: selectedPlaque.name,
        },
        spec: this.props.spec,
        url,
        total: this.getTotalCost(),
        pin,
        imgUrl
      },
      canvasLoaded: this.props.canvasLoaded
    });

    const self = this;

    async function takeSayduckScreenshot() {
      const request = new CustomEvent("sayduck.request-api", {
        bubbles: true,
        composed: true,
        detail: {
          instance: {},
        },
      });
     
      window.dispatchEvent(request);
      const api = await request.detail.instance;
      if (api.scene) {
        api.scene.setCameraAngles(180, 180);
        const photo = await api.scene.requestScreenshot({
          format: "jpeg",
          keepPosition: false,
          noCrop: true,
          width: 800,
          height: 800,
          horizontalAngle: 0,
        });
        self.setState({ productImage: photo.dataUrl });
      }
    }
    takeSayduckScreenshot();
  }

  componentDidUpdate(prevProps) {
    if (this.props.canvasLoaded !== prevProps.canvasLoaded) {
      this.setState({
        canvasLoaded: this.props.canvasLoaded
      })
    }
  }

  onResolved() {
    this.setState({
      valid: true,
    });
  }

  /**
   * send email and place the order
   * hit the API
   */
  placeOrder(order_placed) {
    const { updateOrderStatus } = this.props;
    const emailDetail = {
      orderDetail: this.state.orderDetail,
      userInfo: this.state.userInfo,
      email: getEmailData(
        this.state.orderDetail.activeProductId,
        this.props.spec
      ),
      order_placed,
    };
    const self = this;

    this.recaptcha.execute();

    // update order status in the parent
    updateOrderStatus(order_placed);

    // disable send button
    this.setState({
      sending: "Sending email.",
    });

    axios
      .post("/api/order", {
        details: emailDetail,
      })
      .then(function (response) {
        let orderStatus = false;

        // update the order status baesd on the request
        if (order_placed === 1) {
          orderStatus = true;
        }

        if (response.data && response.data.status === 200) {
          self.setState((prevState) => {
            let newOrderDetail = { ...prevState.orderDetail };
            newOrderDetail.url =
              newOrderDetail.url + "&OI=" + response.data.data.id;

            return {
              orderStatus,
              redirect: true,
              sending: "",
              orderDetail: newOrderDetail,
            };
          });
        }
      })
      .catch(function (error) {
        self.setState({
          error: true,
          message: "There was an error sending the email. Please try again.",
          sending: "",
        });
      });
  }

  getAccessoriesSku(accessories_list) {
    const { product } = this.props;
    let accessories_sku = [];
    accessories_list.forEach((element) => {
      accessories_sku.push(accessories[product][element]);
    });
    return accessories_sku;
  }

  async checkout() {
    // remove confirm box
    await window.dispatchEvent(
      new CustomEvent("sayduck.viewer.actions.takePhoto", {
        detail: {
          format: "jpg",
          keepPosition: false,
          noCrop: false,
          width: 800,
          height: 800,
        },
      })
    );

    window.removeEventListener("beforeunload", this.props.onUnload);
    let ctid = this.props.state.orderId;
    const notify = (message) =>
      toast.error(message, {
        className: "toast",
        autoClose: false,
      });

    let errCount = 0;

    const uploadImage = async () => {
      let imageForm = new FormData();
      imageForm.append("image", this.state.productImage);

      return await axios
        .post('/api/imageUpload', imageForm).then((res) => {
          this.setState({ imgUrl: res.data.data });
          return res.data.data;
        }).catch(err => {
          return 'Sorry, looks like there was a problem with the connection. Please refresh and try again or contact Customer Care at +1 (877) 586-7723'
        });
    }

    const order = async (imgUrl) => {
      return await axios
        .post("/api/order", {
          details: {
            dealer: 0,
            orderDetail: this.state.orderDetail,
            img: imgUrl,
            email: getEmailData(
              this.state.orderDetail.activeProductId,
              this.props.spec
            ),
          },
        }).then((order) => {
          ctid = order?.data?.data?.id;
          if (!ctid) return 'error';

          errCount = 0;
          return {
            ctid,
            image_url: imgUrl,
          }
        }).catch((err) => {
          errCount += 1;
          if (errCount >= 3) {
            notify('Sorry, looks like there was a problem with the connection. Please refresh and try again or contact Customer Care at +1 (877) 586-7723');
            return err;
          } else {
            order();
          }
        });
    }

    const orderUpdate = async (imgUrl, ctid) => {
      return await axios
        .post("/api/order", {
          details: {
            ctid: ctid,
            dealer: 0,
            orderDetail: this.state.orderDetail,
            img: imgUrl,
            email: getEmailData(
              this.state.orderDetail.activeProductId,
              this.props.spec
            ),
          },
        }).then((order) => {
          ctid = order?.data?.data?.id;
          if (!ctid) return 'error';

          errCount = 0
          return {
            ctid,
            image_url: imgUrl,
          }
        }).catch((err) => {
          errCount += 1;
          if (errCount >= 3) {
            notify('Sorry, looks like there was a problem with the connection. Please refresh and try again or contact Customer Care at +1 (877) 586-7723');
            return err;
          }
          else {
            orderUpdate();
          }
        });
    }

    if (!ctid) {
      this.setState({ makingCheckoutRequest: true });
      uploadImage()
        .then(imageValue => {
          if (!imageValue) {
            return 'error';
          }
          return order(imageValue);
        })
        .then(val => {
          if (!val) {
            return 'error';
          }
          return addtocart(val.ctid, val.image_url);
        })
        .catch(err => {
          notify('Sorry, looks like there was a problem with the connection. Please refresh and try again or contact Customer Care at +1 (877) 586-7723');
          this.setState({ makingCheckoutRequest: false });
        })
    } else { 
      // if the order already exists, skip creating the order
      this.setState({ makingCheckoutRequest: true });
      uploadImage()
        .then((imageValue) => {
          if (!imageValue) {
            return 'error';
          }
          return orderUpdate(imageValue, ctid);
        })
        .then((val) => {
          if (!val) {
            return 'error';
          }
          addtocart(val.ctid, val.image_url);
        })
        .catch(err => {
          notify('Sorry, looks like there was a problem with the connection. Please refresh and try again or contact Customer Care at +1 (877) 586-7723');
          this.setState({ makingCheckoutRequest: false });
        })
    }

    const addtocart = (newCtid, newImageUrl) => {
      const { selectedAccessories, product, oldAccessories } = this.props;
      const { activeProduct } = this.props.state
      let selectedAccessoriesSku,
        new_accessories,
        new_accessories_sku,
        removed_accessories,
        removed_accessories_sku = [];
      let is_new = this.props.state.orderId ? false : true;
      const sku = activeProduct.id + 'C';
      selectedAccessoriesSku = this.getAccessoriesSku(selectedAccessories);

      // get new accessories list
      new_accessories = selectedAccessories.filter((element) => {
        return !oldAccessories.includes(element);
      });

      // get new accessories sku
      new_accessories_sku = this.getAccessoriesSku(new_accessories);

      // get removed accessories
      removed_accessories = oldAccessories.filter((element) => {
        return !selectedAccessories.includes(element);
      });

      // get removed accessories sku
      removed_accessories_sku = this.getAccessoriesSku(removed_accessories);
      let url = this.state.orderDetail.url
      let formData = new FormData();

      formData.append("sku", sku);

      formData.append("accessories_sku", selectedAccessoriesSku.toString());
      formData.append("ctid", newCtid);
      formData.append("is_new", is_new);
      formData.append("remove_sku", removed_accessories_sku.toString());
      formData.append("new_sku", new_accessories_sku.toString());
      formData.append(
        "image_url",
        newImageUrl
      );

      let locationArr = window.location.href.split("/");
      let domain =
        locationArr[0] +
        "//" +
        locationArr[2] +
        "/design" +
        url +
        "&OI=" +
        ctid;
      formData.append("url", domain);

      const envDomain = window.location.href;
      let shopifyUrl = "https://3dapi-prod.springfreetrampoline.com/v1/item";
      if (envDomain.indexOf('dev') >= 0) {
        shopifyUrl = "https://3dapi-stage.springfreetrampoline.com/v1/item";
      }

      return new Promise((resolve, reject) => {
        axios
          .post(shopifyUrl, formData)
          .then((data) => {
            const {
              data: { redirect },
            } = data;
            this.setState({ makingCheckoutRequest: false });
            resolve()
            if (redirect) window.location.href = redirect;
          })
          .catch((err) => {
            errCount += 1;
            if (errCount >= 3) {
              let errorMessage = err?.response?.data?.message;
              if (!errorMessage) { 
                errorMessage = 'Sorry, looks like there was a problem with the connection. Please refresh and try again or contact Customer Care at +1 (877) 586-7723' 
              }
              notify(errorMessage);

              this.setState({ makingCheckoutRequest: false });
              reject();
            } else {
              addtocart(formData);
            }
          });
      })
    }
  }

  /**
   * update the value in the userInfo state
   * @param {string} key - key of the value to udpate
   * @param {object} e - javascript event object
   */
  updateFormData(e, key) {
    let updatedValue = e.target.value;
    if (updatedValue === "true" || updatedValue === "false") {
      updatedValue = updatedValue !== "true";
    }

    this.setState((prevState) => {
      prevState.userInfo[key] = updatedValue;
      return {
        userInfo: prevState.userInfo,
      };
    });
  }

  // total cost of all the items in the cart
  getTotalCost() {
    const { location, activeProduct, selectedAccessories } = this.props.state;

    const trampolineCost = parseInt(
      activeProduct.price[location].replace(",", "")
    );
    const customizationCost = +parseInt(199);
    let selectedAccessoriesCost = 0;
    selectedAccessories.forEach((accessory) => {
      const accessoryItem = accessoryData.find((acc) => acc.id === accessory);
      const price = accessoryItem[location][activeProduct.id];
      // remove the coma from the price
      selectedAccessoriesCost += parseInt(price.replace("$", ""));
    });

    return trampolineCost + customizationCost + selectedAccessoriesCost;
  }

  /**
   * Check if the all the form fields are selected
   */
  checkFields() {
    const { userInfo, valid } = this.state;
    const userInfoKeys = Object.keys(userInfo);
    let value = true;
    userInfoKeys.forEach((field) => {
      if (userInfo[field] === "") {
        value = false;
      }
      if (userInfo["acknowledge"] === false) {
        value = false;
      }
    });

    return value;
  }

  render() {
     const style_red_text = {     
      color:'#d90c0c'
      //color:'#6fb243'
    };
    const { spec, fontDetail, image } = this.props;
    const {
      skipPin,
      orderStatus,
      redirect,
      error,
      message,
      sending,
      valid,
      checkoutStatus
    } = this.state;
    const {
      location,
      activeProduct,
      customizationOption,
      selectedTheme,
      selectedAccessories,
    } = this.props.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/design/share",
            search: this.state.orderDetail.url,
            state: { orderStatus },
          }}
        />
      );
    }

    return (
      <div className="order__container">
        <div className="order__image--wrapper">
          <div className="order-product__container">
            <div className="product__information">
              <div>
                <ProductStatus status={activeProduct.status} />
                <h1 className="product__information--name">
                  {activeProduct.name}
                  <span className="product__information--label">
                    Trampoline
                  </span>
                </h1>
                <h2 className="product__information--price">
                  ${activeProduct.price[location]}
                </h2>

                <ProductMessage status={activeProduct.status} left />
              </div>
            </div>
            <div className="order__steps">
              <h3 className="order__steps--heading">Edit previous steps</h3>
              {this.props.children}
            </div>
          </div>
        </div>

        <div className="order-detail__wrapper">
          <div className="order-detail">
            <div className="order-summary">
              <h2>Estimated Costs</h2>
              <div className="order-summary--item">
                <span>{activeProduct.name} Trampoline</span>
                <span>${activeProduct.price[location]}</span>
              </div>
              <div className="order-summary--item">
                <span>Customization Fee</span>
                <span>$199</span>
              </div>

              <SelectedAccessories
                accessoryList={selectedAccessories}
                location={location}
                productId={activeProduct.id}
              />

              <hr className="divider" />
              
              <div className="order-summary--total">
                <span>Sub Total</span>
                <span>${this.getTotalCost()}</span>
              </div>
              <div className="order-affirm-message" style={{"text-align": "right"}}>Financing availiable at checkout <img src="https://static.springfree.com/sites/springfreetrampoline.com/files/images/headers/affirm_black_logo-transparent_bg.png" style={{height: "20px"}} /></div>
            </div>
            {this.props.dealer ? (
              <>
                <h3 className="order__heading">Contact Details</h3>
                <div className="order-detail__form">
                  <div className="input-wrapper">
                    <div className="input-container">
                      <label>
                        First Name<span className="pin__error">*</span>
                      </label>
                      <input
                        type="text"
                        value={this.state.userInfo.firstName}
                        onChange={(e) => this.updateFormData(e, "firstName")}
                      />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <div className="input-container">
                      <label>
                        Last Name<span className="pin__error">*</span>
                      </label>
                      <input
                        type="text"
                        value={this.state.userInfo.lastName}
                        onChange={(e) => this.updateFormData(e, "lastName")}
                      />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <div className="input-container">
                      <label>
                        Phone{!skipPin && <span className="pin__error">*</span>}
                      </label>
                      <input
                        type="tel"
                        value={this.state.userInfo.phone}
                        onChange={(e) => this.updateFormData(e, "phone")}
                      />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <div className="input-container">
                      <label>
                        Email<span className="pin__error">*</span>
                      </label>
                      <input
                        type="email"
                        value={this.state.userInfo.email}
                        onChange={(e) => this.updateFormData(e, "email")}
                      />
                    </div>
                  </div>
                </div>

                <div className="order-detail__form">
                  {!skipPin && [
                    <h3 className="order__heading">
                      Shipping address<span className="pin__error">*</span>
                    </h3>,
                    <div className="input-wrapper address">
                      <div className="input-container">
                        <label>
                          Address<span className="pin__error">*</span>
                        </label>
                        <textarea
                          value={this.state.userInfo.address}
                          onChange={(e) => this.updateFormData(e, "address")}
                        />
                      </div>
                    </div>,
                    <div className="input-wrapper">
                      <div className="input-container">
                        <label>
                          {lang.state[location]}
                          <span className="pin__error">*</span>
                        </label>
                        <input
                          type="text"
                          value={this.state.userInfo.state}
                          onChange={(e) => this.updateFormData(e, "state")}
                        />
                      </div>
                    </div>,
                    <div className="input-wrapper">
                      <div className="input-container">
                        <label>
                          City<span className="pin__error">*</span>
                        </label>
                        <input
                          type="text"
                          value={this.state.userInfo.city}
                          onChange={(e) => this.updateFormData(e, "city")}
                        />
                      </div>
                    </div>,
                    <div className="input-wrapper">
                      <div className="input-container">
                        <label>
                          {lang.zip[location]}
                          <span className="pin__error">*</span>
                        </label>
                        <input
                          type="text"
                          value={this.state.userInfo.zip}
                          onChange={(e) => this.updateFormData(e, "zip")}
                        />
                      </div>
                    </div>,
                    <div className="input-wrapper">
                      <div className="input-container">
                        <label>
                          Country<span className="pin__error">*</span>
                        </label>
                        <input
                          type="text"
                          value={this.state.userInfo.country}
                          onChange={(e) => this.updateFormData(e, "country")}
                        />
                      </div>
                    </div>,
                  ]}

                  <div className="input-wrapper--checkbox">
                    <div className="input-container">
                      <span>
                        <input
                          type="checkbox"
                          value={this.state.userInfo.newsletter}
                          checked={this.state.userInfo.newsletter}
                          onChange={(e) => this.updateFormData(e, "newsletter")}
                        />
                        <span
                          onClick={() =>
                            this.setState({
                              userInfo: {
                                ...this.state.userInfo,
                                newsletter: !this.state.userInfo.newsletter,
                              },
                            })
                          }
                        >
                          Sign up for email newsletter.
                        </span>
                      </span>
                    </div>
                  </div>

                  {!skipPin && (
                    <div className="input-wrapper--checkbox">
                      <div className="input-container">
                        <div>
                          <input
                            type="checkbox"
                            value={this.state.userInfo.acknowledge}
                            checked={this.state.userInfo.acknowledge}
                            onChange={(e) =>
                              this.updateFormData(e, "acknowledge")
                            }
                          />
                          <strong
                            onClick={() =>
                              this.setState({
                                userInfo: {
                                  ...this.state.userInfo,
                                  acknowledge: !this.state.userInfo.acknowledge,
                                },
                              })
                            }
                          >
                            My unique design will be saved by Springfree
                            Trampoline and emailed to me. We encourage you to
                            make and save as many designs as you like – have
                            fun!
                          </strong>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : null}
          </div>
          <Recaptcha
            ref={(ref) => (this.recaptcha = ref)}
            sitekey="6LfF6sAUAAAAADROlxL6tyltvNB0BCCSpLrbFEkt"
            onResolved={this.onResolved}
          />
          {!skipPin && (
            <>
              <div className="order-summary order-confirm__checkout">
                <input
                  type="checkbox"
                  value={this.state.agree}
                  checked={this.state.agree}
                  onChange={(e) =>
                    this.setState({
                      agree: !this.state.agree,
                    })
                  }
                />
                <strong
                  onClick={() =>
                    this.setState({
                      agree: !this.state.agree,
                    })
                  }
                >
                I understand all custom orders are final and there can be no changes, exchanges or returns.*
                </strong>
              </div>

              <div className={`order__actions${!this.state.agree ? ' inactive' : ''}`}>
                <div  style={style_red_text}><strong></strong></div>
                {error && <p className="pin__error">{message}</p>}
                <div className="co19__main-wrapper">
                  <div className="co19__align">
                    {this.props.dealer ? (
                      <button
                        className="btn btn--light-border"
                        onClick={() => this.placeOrder(0) || sending.length > 0}
                        disabled={!this.checkFields()}
                      >
                        {sending || "Email Me My Design"}
                      </button>
                    ) : (
                      <div className="order__actions-container">
                        <span className="checkout-message">
                          {this.state.makingCheckoutRequest ? "Processing request..." : null}
                        </span>
                        {!this.state.canvasLoaded ? (
                          <span>Loading...</span>
                        ) : (
                          <button
                            className="btn btn--light-border"
                            onClick={() => {
                              if (!this.state.agree) {
                                this.setState({
                                  pleaseAgree: true,
                                });
                                return;
                              }
                              this.checkout();
                            }}
                            disabled={
                              this.state.checkoutStatus ||
                              this.state.makingCheckoutRequest
                            }
                          >
                            Checkout
                          </button>
                        )}

                        {this.state.checkoutStatus === 1 && (
                          <div className="">Order already placed!</div>
                        )}

                        {this.state.pleaseAgree && !this.state.agree && (
                          <div className="error">
                            Please agree to the above terms and conditions to
                            proceed
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {skipPin && (
            <div className="order__actions">
              {error && <p className="pin__error">{message}</p>}
              <button
                disabled={sending.length > 0 || !valid}
                className="btn btn--light"
                onClick={() => this.placeOrder(0)}
              >
                {sending || "Please email me my design."}
              </button>
            </div>
          )}
        </div>
        <ToastContainer />
      </div>
    );
  }
}
