export const productData = [
  {
    id: "S155",
    name: "Jumbo Square",
    status: {
      code: 3,
      message: 'Ships within 6 weeks',
    },
    dimension: "13 x 13",
    price: {
      us: "2,499",
      ca: "2,499",
    },
    image: "/img/products/S155-size.svg",
    data: [
      {
        label: "Legs",
        id: "L",
        text: "There are 8 legs and you can choose from 7 color options.",
        unavailableColor: ['red', 'blue'],
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
              {
                label: "4 colors alternating arrangement",
                id: "AL4A",
              },
            ],
          },
          {
            label: "4 colors",
            id: "4",
            options: [
              {
                label: "Random color arrangement",
                id: "RA00",
              },
            ],
          },
        ],
      },
      {
        label: "Frame",
        id: "F",
        text:
          "There are 8 parts of the frame and you can choose from 7 color options.",
        unavailableColor: ['pink', 'red'],
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "4 colors",
            id: "4",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
            ],
          },
        ],
      },
      {
        label: "Mat Rods",
        id: "M",
        text: "There are 76 mat rods and 7 color options",
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
              {
                label: "19 colors alternating arrangement",
                id: "AL19",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "4 colors",
            id: "4",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "19 colors alternating arrangement",
                id: "AL19",
              },
            ],
          },
        ],
      },
      {
        label: "Enclosure Rods",
        id: "E",
        text: "There are 12 enclosure rods and 7 color options",
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "3 colors",
            id: "3",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
              {
                label: "4 colors alternating arrangement",
                id: "AL4A",
              },
            ],
          },
          {
            label: "4 colors",
            id: "4",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "3 colors alternating arrangement",
                id: "AL3A",
              },
            ],
          },
          {
            label: "6 colors",
            id: "6",
            options: [
              {
                label: "1 color random arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors random arrangement",
                id: "AL2A",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "S113",
    name: "Large Square",
    status: {
      code: 3,
      message: 'Ships within 8-10 weeks',
    },
    dimension: "11 x 11",
    price: {
      us: "1,999",
      ca: "2,199",
    },
    image: "/img/products/S113-size.svg",
    data: [
      {
        label: "Legs",
        id: "L",
        text: "There are 8 legs and you can choose from 7 color options.",
        unavailableColor: ['pink'],
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
              {
                label: "4 colors alternating arrangement",
                id: "AL4A",
              },
            ],
          },
          {
            label: "4 colors",
            id: "4",
            options: [
              {
                label: "Random color arrangement",
                id: "RA00",
              },
            ],
          },
        ],
      },
      {
        label: "Frame",
        id: "F",
        text:
          "There are 8 parts of the frame and you can choose from 7 color options.",
        unavailableColor: ['pink'],
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "4 colors",
            id: "4",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
            ],
          },
        ],
      },
      {
        label: "Mat Rods",
        id: "M",
        text: "There are 64 mat rods and 7 color options",
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
              {
                label: "4 colors alternating arrangement",
                id: "AL4A",
              },
              {
                label: "8 colors alternating arrangement",
                id: "AL8A",
              },
              {
                label: "16 colors alternating arrangement",
                id: "AL16",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "4 colors",
            id: "4",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
              {
                label: "4 colors alternating arrangement",
                id: "AL4A",
              },
              {
                label: "8 colors alternating arrangement",
                id: "AL8A",
              },
              {
                label: "16 colors alternating arrangement",
                id: "AL16",
              },
            ],
          },
        ],
      },
      {
        label: "Enclosure Rods",
        id: "E",
        text: "There are 12 enclosure rods and 7 color options",
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "3 colors",
            id: "3",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
              {
                label: "4 colors alternating arrangement",
                id: "AL4A",
              },
            ],
          },
          {
            label: "4 colors",
            id: "4",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "3 colors alternating arrangement",
                id: "AL3A",
              },
            ],
          },
          {
            label: "6 colors",
            id: "6",
            options: [
              {
                label: "1 color random arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors random arrangement",
                id: "AL2A",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "O92",
    name: "Large Oval",
    status: {
      code: 3,
      message: 'Ships within 12-14 weeks',
    },
    dimension: "8 x 13",
    price: {
      us: "1,799",
      ca: "1,999",
    },
    image: "/img/products/O92-size.svg",
    data: [
      {
        label: "Legs",
        id: "L",
        text: "There are 6 legs and you can choose from 7 color options.",
        unavailableColor: ['red', 'pink'],
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "3 colors",
            id: "3",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
            ],
          },
          {
            label: "6 colors",
            id: "6",
            options: [
              {
                label: "Random color arrangement",
                id: "RA00",
              },
            ],
          },
        ],
      },
      {
        label: "Frame",
        id: "F",
        text:
          "There are 6 parts of the frame and you can choose from 7 color options.",
        unavailableColor: ['pink', 'red'],
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "3 colors",
            id: "3",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
            ],
          },
          {
            label: "6 colors",
            id: "6",
            options: [
              {
                label: "Random color arrangement",
                id: "RA00",
              },
            ],
          },
        ],
      },
      {
        label: "Mat Rods",
        id: "M",
        text: "There are 58 mat rods and 7 color options",
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
        ],
      },
      {
        label: "Enclosure Rods",
        id: "E",
        text: "There are 10 enclosure rods and 7 color options",
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "5 colors",
            id: "5",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "O77",
    name: "Medium Oval",
    status: {
      code: 3,
      message: 'Ships within 12-14 weeks',
    },
    dimension: "8 x 11",
     price: {
      us: "1,599",
      ca: "1,749",
    },
    image: "/img/products/O77-size.svg",
    data: [
      {
        label: "Legs",
        id: "L",
        text: "There are 6 legs and you can choose from 7 color options.",
        unavailableColor: ['red', 'pink'],
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "3 colors",
            id: "3",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
            ],
          },
          {
            label: "6 colors",
            id: "6",
            options: [
              {
                label: "Random color arrangement",
                id: "RA00",
              },
            ],
          },
        ],
      },
      {
        label: "Frame",
        id: "F",
        text:
          "There are 6 parts of the frame and you can choose from 7 color options.",
        unavailableColor: ['pink', 'red'],
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "3 colors",
            id: "3",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
            ],
          },
          {
            label: "6 colors",
            id: "6",
            options: [
              {
                label: "Random color arrangement",
                id: "RA00",
              },
            ],
          },
        ],
      },
      {
        label: "Mat Rods",
        id: "M",
        text: "There are 58 mat rods and 7 color options",
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
        ],
      },
      {
        label: "Enclosure Rods",
        id: "E",
        text: "There are 10 enclosure rods and 7 color options",
        config: [
          {
            label: "1 color",
            id: "1",
            options: [],
          },
          {
            label: "2 colors",
            id: "2",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "5 colors",
            id: "5",
            options: [
              {
                label: "1 color alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colors alternating arrangement",
                id: "AL2A",
              },
            ],
          },
        ],
      },
    ],
  },

  // ! keep the code, might be needed in future
  // {
  //   id: "O77",
  //   name: "Medium Oval",
  //   dimension: "8 x 11",
  //   price: {
  //     us: "1,499",
  //     ca: "1,749",
  //   },
  //   image: "/img/products/O77-size.svg",
  //   data: [
  //     {
  //       label: "Legs",
  //       id: "L",
  //       text: "There are 6 legs and you can choose from 7 color options.",
  //       config: [
  //         {
  //           label: "1 color",
  //           id: "1",
  //           options: [],
  //         },
  //         {
  //           label: "2 colors",
  //           id: "2",
  //           options: [
  //             {
  //               label: "1 color alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "Half half",
  //               id: "HH00",
  //             },
  //           ],
  //         },
  //         {
  //           label: "3 colors",
  //           id: "3",
  //           options: [
  //             {
  //               label: "1 color alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colors alternating arrangement",
  //               id: "AL2A",
  //             },
  //           ],
  //         },
  //         {
  //           label: "6 colors",
  //           id: "6",
  //           options: [
  //             {
  //               label: "Random color arrangement",
  //               id: "RA00",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Frame",
  //       id: "F",
  //       text:
  //         "There are 6 parts of the frame and you can choose from 7 color options.",
  //       config: [
  //         {
  //           label: "1 color",
  //           id: "1",
  //           options: [],
  //         },
  //         {
  //           label: "2 colors",
  //           id: "2",
  //           options: [
  //             {
  //               label: "1 color alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "Half half",
  //               id: "HH00",
  //             },
  //           ],
  //         },
  //         {
  //           label: "3 colors",
  //           id: "3",
  //           options: [
  //             {
  //               label: "1 color alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colors alternating arrangement",
  //               id: "AL2A",
  //             },
  //           ],
  //         },
  //         {
  //           label: "6 colors",
  //           id: "6",
  //           options: [
  //             {
  //               label: "Random color arrangement",
  //               id: "RA00",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Mat Rods",
  //       id: "M",
  //       text: "There are 52 mat rods and 7 color options",
  //       config: [
  //         {
  //           label: "1 color",
  //           id: "1",
  //           options: [],
  //         },
  //         {
  //           label: "2 colors",
  //           id: "2",
  //           options: [
  //             {
  //               label: "1 color alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colors alternating arrangement",
  //               id: "AL2A",
  //             },
  //             {
  //               label: "13 colors alternating arrangement",
  //               id: "AL13",
  //             },
  //             {
  //               label: "Half half",
  //               id: "HH00",
  //             },
  //           ],
  //         },
  //         {
  //           label: "4 colors",
  //           id: "4",
  //           options: [
  //             {
  //               label: "1 color alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "13 colors alternating arrangement",
  //               id: "AL13",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Enclosure Rods",
  //       id: "E",
  //       text: "There are 10 enclosure rods and 7 color options",
  //       config: [
  //         {
  //           label: "1 color",
  //           id: "1",
  //           options: [],
  //         },
  //         {
  //           label: "2 colors",
  //           id: "2",
  //           options: [
  //             {
  //               label: "1 color alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "Half half",
  //               id: "HH00",
  //             },
  //           ],
  //         },
  //         {
  //           label: "5 colors",
  //           id: "5",
  //           options: [
  //             {
  //               label: "1 color alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colors alternating arrangement",
  //               id: "AL2A",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: "R132",
  //   name: "Jumbo Round Smart",
  //   dimension: "13 x 13",
  //   price: "",
  //   image: "/img/products/R132-size.svg",
  // },
  // {
  //   id: "R79",
  //   name: "Medium Round",
  //   dimension: "10 x 10",
  //   price: "",
  //   image: "/img/products/R79-size.svg",
  // },
  // {
  //   id: "R54",
  //   name: "Compact Round",
  //   dimension: "8 x 8",
  //   price: "",
  //   image: "/img/products/R54-size.svg",
  // },
];
