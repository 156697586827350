import { colorsUrls } from "./trampolineColors";
import { arrangement } from "./trampolineArrangements";

export const updateTrampolineImage = (
  sayduckInstance,
  spec,
  legMaterials,
  frameMaterials,
  MatRodMaterials,
  EnclosurerodsMaterials,
  productId,
  activeStep
) => {
  let productArrangement = arrangement[productId];
  let jump = 0;
  let selectedColors = [];
  let oneColorArrangement = false;
  if (activeStep === 1) {
    updateMaterial(
      sayduckInstance,
      spec,
      legMaterials,
      oneColorArrangement,
      jump,
      productArrangement,
      selectedColors,
      "L"
    );
  } else if (activeStep === 2) {
    updateMaterial(
      sayduckInstance,
      spec,
      frameMaterials,
      oneColorArrangement,
      jump,
      productArrangement,
      selectedColors,
      "F"
    );
  } else if (activeStep === 3) {
    updateMaterial(
      sayduckInstance,
      spec,
      MatRodMaterials,
      oneColorArrangement,
      jump,
      productArrangement,
      selectedColors,
      "M"
    );
  } else if (activeStep === 4) {
    updateMaterial(
      sayduckInstance,
      spec,
      EnclosurerodsMaterials,
      oneColorArrangement,
      jump,
      productArrangement,
      selectedColors,
      "E"
    );
  } else {
    updateMaterial(
      sayduckInstance,
      spec,
      legMaterials,
      oneColorArrangement,
      jump,
      productArrangement,
      selectedColors,
      "L"
    );
    updateMaterial(
      sayduckInstance,
      spec,
      frameMaterials,
      oneColorArrangement,
      jump,
      productArrangement,
      selectedColors,
      "F"
    );
    updateMaterial(
      sayduckInstance,
      spec,
      MatRodMaterials,
      oneColorArrangement,
      jump,
      productArrangement,
      selectedColors,
      "M"
    );
    updateMaterial(
      sayduckInstance,
      spec,
      EnclosurerodsMaterials,
      oneColorArrangement,
      jump,
      productArrangement,
      selectedColors,
      "E"
    );
  }
};

const updateMaterial = (
  sayduckInstance,
  spec,
  materials,
  oneColorArrangement,
  jump,
  productArrangement,
  selectedColors,
  part
) => {
  let selectedArrangement = spec[part].arrangement;
  if (selectedArrangement === 1) {
    oneColorArrangement = true;
  }
  jump = getJump(selectedArrangement, productArrangement, jump, part);
  selectedColors = getSelectedColors(
    selectedArrangement,
    spec,
    selectedColors,
    part,
    oneColorArrangement
  );
  let detail = updateTrampoline(materials, jump, selectedColors);
  dispatchUpdateEvent(sayduckInstance, detail);
};

const getJump = (selectedArrangement, productArrangement, jump, part) => {
  //get jump value
  jump = productArrangement[part][selectedArrangement];
  return jump;
};

const getSelectedColors = (
  selectedArrangement,
  spec,
  selectedColors,
  part,
  oneColorArrangement
) => {
  selectedColors = [];
  //no of colors chosen
  let noOfColorsChosen = 1;
  if (!oneColorArrangement) {
    noOfColorsChosen = selectedArrangement.slice(0, 1);
  }
  let material = part.toLowerCase();
  for (let i = 0; i < noOfColorsChosen; i++) {
    selectedColors.push(spec[part][`${material}${i + 1}`]);
  }
  return selectedColors;
};

const updateTrampoline = (materials, jump, selectedColors) => {
  // get array of materials with colors to update
  let detail = [];
  let currentJump = 0;

  for (let i = 0, currentColor = 0; i < materials.length; i++) {
    let material = {
      materialUuid: "",
      baseColorMapUrl: "",
    };
    if (currentJump >= jump) {
      currentColor++;
      currentJump = 0;
    }
    if (currentColor > selectedColors.length - 1) {
      currentColor = 0;
    }
    material.materialUuid = materials[i].uuid;
    material.baseColorMapUrl = colorsUrls[selectedColors[currentColor]];
    detail.push(material);
    currentJump++;
  }
  return detail;
};

const dispatchUpdateEvent = async (sayduckInstance, detail) => {
  // pass detail to dispatch event
  if (sayduckInstance) {
    for (let i = 0; i < detail.length; i++) {
      try {
        await sayduckInstance.updateMaterial(detail[i].materialUuid, {
          albedoTexture: detail[i].baseColorMapUrl,
        });
      } catch (e) {
        // Invalid Material ID - Ignored
        // console.warn(e);
      }
    }
  }
};
