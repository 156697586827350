import React, { Component } from 'react';
import ModelCards from '../ModelCards';
import './productSelector.style.css';
import ScrollView from '../../../ScrollView';
import { sliderSettings } from '../../../ScrollView/sliderSettings';
import Covid19Message from '../../../Covid19Message';

export default class ProductSelector extends Component {
  state = {
    activeIndex: 0,
  };

  render() {

    const prop = this.props;
    if (prop.activeStep === 0.1) {
      return (
        <div className="customization">
          <div
            className="customization__option customization__option--1"
            onClick={() => this.props.pickCustomizationOption('custom')}
          >
            <h2 className="customization__heading">Design Your Own</h2>
            <p>Make it your own with a Springfree designed exclusively by you!</p>
            <Covid19Message small />
            <p>
              <button className="btn btn--border">Start</button>
            </p>
          </div>
          <div
            className="customization__option customization__option--2"
            onClick={() => this.props.pickCustomizationOption('theme')}
          >
            <h2 className="customization__heading">Start with a Signature Model</h2>
            <p>Choose or start your design from one of our pre-designed themes.</p>
            <Covid19Message small />
            <p>
              <button className="btn btn--border">Start</button>
            </p>
          </div>
        </div>
      );
    }
    return (
      <ScrollView
        heading={`Choose your trampoline model`}
        settings={sliderSettings(this)}
        activeIndex={this.state.activeIndex}
      >        

        {prop.products.map((product, index) => {
          return <ModelCards key={product.id} {...prop} {...product} cardIndex={index} selectedModel={this.props.selectedModel} />;
        })}
      </ScrollView>
    );
  }
}
