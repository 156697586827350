import React, { Component } from 'react';
import './ColorPicker.style.css';
import { colors,colorsUrls } from '../../../../helpers/trampolineColors';

export default class ColorPicker extends Component {
  state = {
    colors: [
      {
        label: 'Black',
        value: `#${colors.black}`,
        url: colorsUrls.black,
        newValue: '#2a2a2d'
      },
      {
        label: 'Gunmetal',
        value: `#${colors.gunmetal}`,
        url: colorsUrls.black,
        newValue: '#686662',
      },
      {
        label: 'White',
        value: `#${colors.white}`,
        url: colorsUrls.black,
        newValue: '#faf4f0',
      },
      {
        label: 'Red',
        value: `#${colors.red}`,
        url: colorsUrls.red,
        newValue: '#f11d20',
      },
      {
        label: 'Green',
        value: `#${colors.green}`,
        url: colorsUrls.green,
        newValue: '#00bc38',
      },
      {
        label: 'Blue',
        value: `#${colors.blue}`,
        url: colorsUrls.blue,
        newValue: '#0053b0',
      },
      {
        label: 'Pink',
        value: `#${colors.pink}`,
        url: colorsUrls.black,
        newValue: '#ff93c5',
      },
    ],
  };

  /**
   * @param {object} colorPicked - the clicked color
   * @param {string} colorPicked.label - name of the color
   * @param {string} colorPicked.value - hex value of the color
   */
  selectColor(colorPicked) {
    const { max, updateSelectedColor, dropSelectedColor, activeColors } = this.props;
    let maxVal = parseInt(max.toString().slice(0, 1));
    maxVal = maxVal === 0 ? 1 : maxVal;

    if (activeColors.includes(colorPicked.value)) {
      const indexOfColor = activeColors.indexOf(colorPicked.value);
      dropSelectedColor(indexOfColor);
    } else {
      if (activeColors.length < maxVal) {
        updateSelectedColor(colorPicked.value);
      } else {
        //let indexOfColor = activeColors.indexOf(colorPicked.value);
        dropSelectedColor(0);
        updateSelectedColor(colorPicked.value);
      }
    }
  }
  /**
   * render the sequence of the colors
   * check if the color value is already selected list
   *  return the position of the color in the list
   * @param {string} colorValue - hex value of the color
   */
  colorPosition(colorValue) {
    const { activeColors } = this.props;
    if (activeColors.includes(colorValue)) {
      return <span className="color-position">{activeColors.indexOf(colorValue) + 1}</span>;
    }
    return null;
  }

  render() {
    let { activeColors, max } = this.props;
    max = parseInt(max) === 0 ? 1 : max;

    const maxVal = max.toString().slice(0, 1);
    const pillStyle = color => {
      let pillClass = `color-pill__wrapper ${color.label.toLocaleLowerCase()}`;
      if (activeColors.includes(color.value)) {
        pillClass += ' active';
      }

      return pillClass;
    };

    if (parseInt(maxVal) < 1) {
      return null;
    }

    return (
      <div className="color-picker__container">
        {parseInt(maxVal) > 0 && (
          <p>
            Select {maxVal} {parseInt(maxVal) === 1 ? 'color' : 'colors'}
          </p>
        )}

        {!maxVal && <p className="pin__error">Select an arrangement</p>}

        <div className="color-picker">
          {this.state.colors.map(color => {
            const inactive = this.props.unavailableColor.includes(color.label.toLocaleLowerCase());
            return (
              <div
                onClick={() => this.selectColor(color)}
                className={`${pillStyle(color)} ${inactive ? 'inactive' : ''}`}
                key={color.value}
                style={
                  {
                    // backgroundColor: activeColors.includes(color.value) ? color.value : '#ffffff',
                    // boxShadow: activeColors.includes(color.value)
                    //   ? `0px -26px 35px -30px ${color.value}`
                    //   : '0 0 0 rgba(0,0,0,0)',
                  }
                }
              >
                <span style={{ backgroundColor: color.newValue }} className="color-pill"></span>
                <span className="color-pill__label">
                  {color.label} {this.colorPosition(color.value)}
                </span>
              </div>
            );
          })}
        </div>

        <div className="color-notified">Don’t see the color you like? <a target="_blank" href="https://www.springfreetrampoline.com/get-notified/" rel="noopener noreferrer">Sign up to Get Notified!</a>
        </div>
        
      </div>
    );
  }
}
